<template>
  <div class="comic-reader-container" @scroll="handleScroll">
    <!-- 漫画图片列表 -->
    <div class="comic-images">
      <div
        v-for="(image, index) in displayedImages"
        :key="index"
        class="comic-image-container"
      >
        <img :src="image" alt="Comic Page" class="comic-image" />
      </div>
    </div>
  </div>
</template>

<script src="../scripts/ComicReader.js"></script>
<style scoped src="../styles/ComicReader.css"></style>
