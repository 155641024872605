export default {
  name: "MyPurchaseHistory",
  data() {
    return {
      purchaseRecords: [
        {
          date: "2024-03-09 23:56:07",
          paymentMethod: "卡密支付",
          amount: 6,
          price: "6.00",
        },
        {
          date: "2024-03-08 15:30:45",
          paymentMethod: "余额支付",
          amount: 10,
          price: "10.00",
        },
      ],
      currentPage: 1,
      totalPages: 3, // 示例值，可以在实际应用中动态计算
    };
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};