export default {
  name: 'UserCenter',
  components: {
    Balance: require('@/components/OperationArea/Balance.vue').default,
    Membership: require('@/components/OperationArea/Membership.vue').default,
    PurchaseHistory: require('@/components/OperationArea/PurchaseHistory.vue')
      .default,
    UserBaseInfo: require('@/components/OperationArea/UserBaseInfo.vue')
      .default,
    UserFavorites: require('@/components/OperationArea/UserFavorites.vue')
      .default,
    UserAccountBinding:
      require('@/components/OperationArea/UserAccountBinding.vue').default,
    UserPasswordSetting:
      require('@/components/OperationArea/UserPasswordSetting.vue').default
  },
  data() {
    return {
      user: {
        avatar: require('@/assets/images/default-avatar.png'),
        name: '用户名',
        type: '普通会员',
        email: 'user@example.com'
      },
      activeComponent: 'Balance', // 默认显示的组件
      activeSubMenu: 'Balance', // 默认选中的二级菜单
      showTooltipMessage: false,
      checkinCompleted: false, // 是否已签到
      showHoverTooltip: false, // 控制鼠标悬停提示
      showCheckinSuccess: false, // 是否显示签到成功提示
      coins: 2 // 用户的金币数量
    }
  },
  methods: {
    // 导航至用户中心的指定模块
    navigateTo(module) {
      if (
        [
          'Balance',
          'Membership',
          'PurchaseHistory',
          'UserBaseInfo',
          'UserFavorites',
          'UserAccountBinding',
          'UserPasswordSetting'
        ].includes(module)
      ) {
        this.activeComponent = module
        this.activeSubMenu = module
      } else {
        console.warn(`组件 ${module} 不存在`)
      }
    },
    // 设置跳转至余额充值中心
    navigateToRechargeCenter() {
      this.activeComponent = 'Balance'
      this.activeSubMenu = 'Balance' // 同步更新二级菜单选中状态
    },
    showTooltip() {
      this.showTooltipMessage = true
    },
    hideTooltip() {
      this.showTooltipMessage = false
    },
    handleDailyCheckin() {
      // 签到逻辑
      this.coins += 2 // 增加金币
      this.checkinCompleted = true // 设置签到完成
      this.showCheckinSuccess = true // 显示签到成功提示
    },
    closeCheckinSuccess() {
      this.showCheckinSuccess = false
    },
    // 退出登录
    logout() {
      console.log('用户已退出登录')
    }
  }
}
