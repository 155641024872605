export default {
  name: "UserCard",
  props: {
    showUserCard: Boolean,
  },
  data() {
    return {
      user: {
        name: "用户名",
        type: "普通用户",
        avatar: require('@/assets/images/default-avatar.png'), 
        balance: "100",
        spent: "500",
      },
      otherOptions: [
        { text: "基本资料", icon: "material-icons info" },
        { text: "我的余额", icon: "material-icons account_balance_wallet" },
        { text: "我的会员", icon: "material-icons stars" },
        { text: "我的收藏", icon: "material-icons favorite" },
        { text: "购买记录", icon: "material-icons history" },
      ],
    };
  },
  methods: {
    navigateTo(section) {
      this.$router.push({ name: 'UserCenter', params: { section } });
    },
    logout() {
      alert("已退出登录");
    },
    hideCard() {
      this.$emit("hide");
    },
  }
};