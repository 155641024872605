<template>
  <div class="login-overlay">
    <div class="login-popup">
      <!-- 右上角关闭按钮 -->
      <button class="close-button" @click="$emit('close')">✖</button>
      
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      
      <!-- 账号部分 -->
      <div class="input-group">
        <label for="username">账号 <span class="required-indicator">*</span></label>
        <input id="username" type="text" placeholder="请输入电子邮箱/用户名" />
      </div>
      
      <!-- 密码部分 -->
      <div class="input-group">
        <label for="password">密码 <span class="required-indicator">*</span><a href="#" class="forgot-password">忘记密码？</a></label>
        <input id="password" type="password" placeholder="请输入密码" />
      </div>
      
      <!-- 登录按钮 -->
      <button class="login-button">立即登录</button>
      
      <!-- 注册提示 -->
      <p class="register-prompt">
        还没有账号？<a href="#" class="register-link">现在注册</a>
      </p>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "Login",
  };
  </script>

<style scoped src="../styles/Login.css"></style>