<template>
  <div class="balance-container">
    <h2 class="balance-title">
      <i class="material-icons">account_balance_wallet</i> 余额充值中心
    </h2>

    <div class="balance-summary">
      <div class="balance-card current-balance">
        <div class="balance-amount-container">
          <div class="balance-amount">2 金币</div>
          <div class="balance-label">当前余额</div>
        </div>
        <i class="material-icons balance-icon">account_balance</i>
      </div>

      <div class="balance-card total-spent">
        <div class="balance-amount-container">
          <div class="balance-amount">6 金币</div>
          <div class="balance-label">累计消费</div>
        </div>
        <i class="material-icons balance-icon">credit_card</i>
      </div>
    </div>

    <!-- 充值项目说明 -->
    <div class="recharge-description">
      充值项目（充值比例：1元=1金币）
    </div>

    <!-- 充值项目按钮列表 -->
    <div class="recharge-options">
      <div v-for="(amount, index) in rechargeOptions" :key="index"
        :class="['recharge-button', { selected: selectedAmount === amount.value }]" @click="selectAmount(amount.value)">
        <div class="recharge-amount">{{ amount.label }}</div>
        <div class="recharge-price">¥{{ amount.price }}</div>
        <i v-if="selectedAmount === amount.value" class="material-icons check-icon">check_circle</i>
      </div>
    </div>

    <!-- 支付金额和使用密卡支付按钮 -->
    <div class="payment-options">
      <span class="payment-amount">支付金额：¥{{ selectedAmount || 0 }}</span>
      <button class="mika-payment-button">使用卡密充值</button>
    </div>

    <div class="recharge-info">
      <div class="recharge-description">
        <ul>
          <li>充值说明：</li>
          <li>充值最低额度为1金币</li>
          <li>充值汇率为1金币=1人名币</li>
          <li>金币和人名币不能互相转换</li>
          <li>余额永久有效，无时间限制</li>
          <li>成功充值后暂不支持退款</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="../../scripts/OperationArea/Balance.js"></script>
<style scoped src="../../styles/OperationArea/Balance.css"></style>