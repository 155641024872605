<template>
  <div class="account-binding-container">
    <h4 class="section-title">账号邮箱绑定</h4>

    <div class="binding-info">
      <!-- 当前绑定邮箱 -->
      <div class="binding-row">
        <label for="current-email">当前绑定邮箱</label>
        <input type="text" id="current-email" value="820146275@qq.com" disabled class="input-field" />
      </div>

      <!-- 绑定新邮箱 -->
      <div class="binding-row">
        <label for="new-email">绑定新邮箱</label>
        <input type="text" id="new-email" placeholder="输入要新邮箱账号" class="input-field" />
        <button class="bind-button">绑定新邮箱</button>
      </div>
    </div>
  </div>
</template>

<script src="../../scripts/OperationArea/UserAccountBinding.js"></script>
<style scoped src="../../styles/OperationArea/UserAccountBinding.css"></style>