<template>
  <div class="password-setting-container">
    <h4 class="section-title">账号密码管理</h4>

    <div class="password-form">
      <!-- 旧密码 -->
      <div class="password-row">
        <label for="old-password">旧密码</label>
        <input type="password" id="old-password" placeholder="请输入旧密码" class="input-field" />
      </div>

      <!-- 新密码 -->
      <div class="password-row">
        <label for="new-password">新密码</label>
        <input type="password" id="new-password" placeholder="请输入新密码" class="input-field" />
      </div>

      <!-- 确认新密码 -->
      <div class="password-row">
        <label for="confirm-password">确认新密码</label>
        <input type="password" id="confirm-password" placeholder="请再次输入新密码" class="input-field" />
        <button class="update-button">确认修改密码</button>
      </div>
    </div>
  </div>
</template>

<script src="../../scripts/OperationArea/UserPasswordSetting.js"></script>
<style scoped src="../../styles/OperationArea/UserPasswordSetting.css"></style>