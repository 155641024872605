<template>
  <div class="home">
    <!-- 导航栏 -->
    <NavBar />
    <!-- 搜索框区域 -->
    <div class="search-section">
      <p class="search-prompt">Comic Base</p>
      <el-input v-model="searchText" placeholder="搜索你想看的漫画吧..." clearable class="search-input">
        <template #suffix>
          <el-icon class="search-icon">
            <Search />
          </el-icon>
        </template>
      </el-input>
    </div>

    <!-- 广告轮播区域 -->
    <div class="carousel-section">
      <el-carousel :interval="2000" arrow="always">
        <el-carousel-item v-for="(image, index) in images" :key="index">
          <img :src="image" class="carousel-image" />
        </el-carousel-item>
      </el-carousel>
    </div>


    <!-- 漫画展示区域 -->
    <div class="comic-section">
      <div v-for="category in categories" :key="category.name" class="comic-category">
        <h3 class="category-title">{{ category.name }}</h3>
        <div class="comics-grid">
          <div v-for="comic in category.comics" :key="comic.title" class="comic-card" @click="goToDetail(comic.id)">
            <img :src="comic.cover" alt="漫画封面" class="comic-cover" />
            <div class="comic-info">
              <p class="comic-type">{{ category.name }}</p>
              <p class="comic-description">{{ comic.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部区域 -->
    <Footer />
  </div>
</template>

<script src="../scripts/Home.js"></script>

<style scoped src="../styles/Home.css"></style>
<style scoped src="../styles/HomeSearch.css"></style>
<style scoped src="../styles/HomeCarousel.css"></style>
<style scoped src="../styles/HomeComics.css"></style>