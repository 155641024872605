export default {
    name: "UserBaseInfo",
    data() {
      return {
        // 可在此处添加表单数据绑定
      };
    },
    methods: {
      saveInfo() {
        console.log("保存个人信息");
      },
    },
  };