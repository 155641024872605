import { ElInput, ElIcon, ElCarousel, ElCarouselItem } from 'element-plus';
import 'element-plus/dist/index.css';
import { Search } from '@element-plus/icons-vue';

export default {
  components: {
    ElInput,
    ElIcon,
    ElCarousel,
    ElCarouselItem,
    Search
  },
  data() {
    return {
      searchText: '',
      images: [
        require('@/assets/images/ad1.jpg'),
        require('@/assets/images/ad2.jpg'),
        require('@/assets/images/ad3.jpg'),
        require('@/assets/images/ad4.jpg'),
        require('@/assets/images/ad5.jpg')
      ],
      categories: [
        {
          name: '热血竞技',
          comics: [
            { id: 1, title: '漫画1', cover: require('@/assets/images/comic1.jpg'), description: '精彩的热血故事1' },
            { id: 2, title: '漫画2', cover: require('@/assets/images/comic2.jpg'), description: '精彩的热血故事2' },
            { id: 3, title: '漫画3', cover: require('@/assets/images/comic3.jpg'), description: '精彩的热血故事3' },
            { id: 4, title: '漫画4', cover: require('@/assets/images/comic4.jpg'), description: '精彩的热血故事4' }
          ]
        },
        {
          name: '青春恋爱',
          comics: [
            { id: 5, title: '漫画5', cover: require('@/assets/images/comic5.jpg'), description: '浪漫的青春故事1' },
            { id: 6, title: '漫画6', cover: require('@/assets/images/comic6.jpg'), description: '浪漫的青春故事2' },
            { id: 7, title: '漫画7', cover: require('@/assets/images/comic7.jpg'), description: '浪漫的青春故事3' },
            { id: 8, title: '漫画8', cover: require('@/assets/images/comic8.jpg'), description: '浪漫的青春故事4' }
          ]
        },
        {
          name: '冒险悬疑',
          comics: [
            { id: 9, title: '漫画9', cover: require('@/assets/images/comic9.jpg'), description: '紧张的冒险故事1' },
            { id: 10, title: '漫画10', cover: require('@/assets/images/comic10.jpg'), description: '紧张的冒险故事2' },
            { id: 11, title: '漫画11', cover: require('@/assets/images/comic11.jpg'), description: '紧张的冒险故事3' },
            { id: 12, title: '漫画12', cover: require('@/assets/images/comic12.jpg'), description: '紧张的冒险故事4' }
          ]
        },
        {
          name: '幽默格斗',
          comics: [
            { id: 13, title: '漫画13', cover: require('@/assets/images/comic13.jpg'), description: '搞笑的格斗故事1' },
            { id: 14, title: '漫画14', cover: require('@/assets/images/comic14.jpg'), description: '搞笑的格斗故事2' },
            { id: 15, title: '漫画15', cover: require('@/assets/images/comic15.jpg'), description: '搞笑的格斗故事3' },
            { id: 16, title: '漫画16', cover: require('@/assets/images/comic16.jpg'), description: '搞笑的格斗故事4' }
          ]
        }
      ]
    };
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: 'ComicDetail', params: { id } });
    },
  },
};