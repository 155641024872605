export default {
  name: "Membership",
  data() {
    return {
      selectedPlan: 0, // 默认选中的会员计划
      membershipPlans: [
        {
          name: "月度会员",
          price: 48,
          discount: "春季优惠，原价68",
          benefits: ["会员时长：30天", "享受会员专属折扣", "海量资源免费更新"],
          color: "#ff8a65",
        },
        {
          name: "季度会员",
          price: 78,
          discount: "春季优惠，原价108",
          benefits: ["会员时长：90天", "享受会员专属折扣", "海量资源免费更新"],
          color: "#4caf50",
        },
        {
          name: "永久会员",
          price: 148,
          discount: "春季优惠，原价198",
          benefits: ["会员时长：永久", "享受会员专属折扣", "高速下载不限速"],
          color: "#7986cb",
        },
      ],
    };
  },
  methods: {
    selectPlan(index) {
      this.selectedPlan = index;
    },
  },
};