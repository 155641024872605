<template>
  <div class="membership-center">
    <!-- 顶部会员信息 -->
    <div class="membership-header">
      <i class="material-icons">workspace_premium</i> <!-- 替换为新的会员图标 -->
      <h4>开通VIP会员，尊享VIP优惠特权</h4>
    </div>

    <!-- 会员套餐选择 -->
    <div class="membership-options">
      <div v-for="(plan, index) in membershipPlans" :key="index"
        :class="['membership-panel', { selected: selectedPlan === index }]" @click="selectPlan(index)">
        <i class="material-icons">diamond</i>
        <h5 class="membership-title">{{ plan.name }}</h5>
        <div class="membership-price">{{ plan.price }}金币</div>
        <div class="membership-discount">{{ plan.discount }}</div>
        <p class="membership-benefit" v-for="benefit in plan.benefits" :key="benefit">{{ benefit }}</p>
        <div v-if="selectedPlan === index" class="check-icon">
          <i class="material-icons">check_circle</i>
        </div>
      </div>
    </div>

    <!-- 会员状态信息 -->
    <div class="membership-info">
      <div class="user-info">
        <img class="user-avatar" src="@/assets/images/default-avatar.png" alt="用户头像" />
        <div class="user-details">
          <div class="user-name-type">
            <p class="user-name">新用户</p>
            <p class="user-type">普通</p>
          </div>
          <p class="expiry-date">到期时间：2024-11-07</p>
        </div>
      </div>
      <button class="open-now-button">立即开通</button>
    </div>

    <!-- 会员说明 -->
    <div class="membership-description">
      <div class="recharge-info"> <!-- 为说明区域设置统一样式 -->
        <ul class="recharge-description">
          <li>开通会员说明：</li>
          <li>本站会员账号限为虚拟数字资源，开通后不可退款</li>
          <li>开通会员后可享有对应会员特权的商品折扣，免费权益</li>
          <li>会员特权到期后不享受特权</li>
          <li>重复购买特权到期时间累计增加</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="../../scripts/OperationArea/Membership.js"></script>
<style scoped src="../../styles/OperationArea/Membership.css"></style>