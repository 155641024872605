<template>
  <div class="search-modal-overlay" @click.self="closeModal">
    <div class="search-modal">
      <!-- 搜索区域 -->
      <div class="search-area">
        <div class="search-input-group">
          <i class="material-icons search-icon">search</i>
          <!-- 标签下拉按钮，内部右侧图标 -->
          <div class="tag-dropdown" @click="toggleDropdown" ref="dropdown">
            <span>{{ selectedTag }}</span>
            <!-- 内部右侧向下图标 -->
            <span class="dropdown-arrow">▼</span> 
            <div v-if="showDropdown" class="dropdown-menu">
              <ul>
                <li v-for="tag in tags" :key="tag" @click="selectTag(tag)">
                  {{ tag }}
                </li>
              </ul>
            </div>
          </div>
          <input class="search-input" type="text" placeholder="输入关键词，回车搜索吧" />
        </div>
      </div>

      <!-- 最近搜索区域 -->
      <div class="recent-search-area">
        <div class="recent-title">
          <span class="recent-dot"></span> 最近搜索
        </div>
        <div class="recent-searches">
          <span class="recent-item" v-for="(item, index) in recentSearches" :key="index">
            {{ item }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../scripts/SearchModal.js"></script>
<style scoped src="../styles/SearchModal.css"></style>