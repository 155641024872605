<template>
  <div class="category-page">
    <!-- 分类区域 -->
    <div class="category-section">
      <h2 class="section-title">分类</h2>
      <div class="category-list">
        <button class="category-button">热血竞技</button>
        <button class="category-button">青春恋爱</button>
        <button class="category-button">冒险悬疑</button>
        <button class="category-button">幽默格斗</button>
      </div>
    </div>

    <!-- 漫画区域 -->
    <div class="comic-section">
      <div class="comic-grid">
        <div
          v-for="comic in comics"
          :key="comic.id"
          class="comic-card"
          @click="goToComicDetail(comic.id)"
        >
          <img :src="comic.cover" alt="漫画封面" class="comic-cover" />
          <h4 class="comic-title">{{ comic.title }}</h4>
          <p class="comic-type">{{ comic.type }}</p>
        </div>
      </div>
      <!-- 分页信息 -->
      <div class="pagination">
        <button class="page-button">上一页</button>
        <span>1 / 5</span>
        <button class="page-button">下一页</button>
      </div>
    </div>
  </div>
</template>

<script src="../scripts/ComicCategory.js"></script>
<style scoped src="../styles/ComicCategory.css"></style>
