<template>
  <div class="comic-detail">
    <div class="intro-section">
      <div class="cover">
        <img :src="comic.cover" alt="漫画封面" class="cover-image" />
      </div>
      <div class="info">
        <div class="title-wrapper">
          <h1 class="title">{{ comic.title }}</h1>
        </div>
        <div class="details">
          <div class="type-wrapper">
            <span class="type">{{ comic.type }}</span>
          </div>
          <div class="status-wrapper">
            <span class="status">{{ comic.status }}</span>
          </div>
        </div>
        <div class="author-wrapper">
          <p class="author">作者: {{ comic.author }}</p>
        </div>
        <div class="description-wrapper">
          <p class="description">{{ comic.description }}</p>
        </div>
      </div>
    </div>

    <!-- 卷目录区域 -->
    <div class="chapters-container">
      <div class="section-header">
        <span class="chapters-dot"></span>
        <h2 class="section-title">卷目录</h2>
      </div>
      <div class="chapters-section">
        <button
          v-for="(chapter, index) in chapters"
          :key="index"
          class="chapter-button"
          @click="goToReadingPage(index + 1)"
        >
          {{ chapter.title }}
        </button>
      </div>
    </div>

    <!-- 评论区域 -->
    <div class="comments-container">
      <div class="section-header">
        <span class="comments-dot"></span>
        <h2 class="section-title">用户评论</h2>
      </div>
      <div class="comments-section">
        <div
          class="comment"
          v-for="(comment, index) in comments.slice(0, 5)"
          :key="index"
        >
          <div class="comment-author">
            <span>{{ comment.user }}</span>
            <span>{{ comment.date }}</span>
          </div>
          <p class="comment-text">{{ comment.text }}</p>
        </div>
        <textarea
          v-model="newComment"
          placeholder="写下你的评论..."
          class="comment-input"
        ></textarea>
        <button @click="submitComment" class="add-comment-button">提交</button>
      </div>
    </div>

    <!-- 相关推荐区域 -->
    <div class="related-section">
      <div class="related-header">
        <span class="related-dot"></span>
        <h3 class="related-title">相关推荐</h3>
      </div>
      <div class="related-grid">
        <div
          v-for="(relatedComic, index) in relatedComics"
          :key="index"
          class="related-card"
          @click="goToComicDetail(relatedComic.id)"
        >
          <img
            :src="relatedComic.cover"
            alt="相关漫画封面"
            class="related-cover"
          />
          <div class="related-info">
            <div class="related-name-wrapper">
              <h4 class="related-name">{{ relatedComic.title }}</h4>
            </div>
            <div class="related-details-wrapper">
              <span class="related-type">{{ relatedComic.type }}</span>
              <span class="related-item">
                <i class="material-icons related-icon">schedule</i>
                {{ relatedComic.uploadDate }}
              </span>
              <span class="related-item">
                <i class="material-icons related-icon">visibility</i>
                {{ relatedComic.views }} 次观看
              </span>
              <span class="related-item">
                <i class="material-icons related-icon">star_border</i>
                {{ relatedComic.favorites }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../scripts/ComicDetail.js"></script>

<style scoped src="../styles/ComicDetail.css"></style>
