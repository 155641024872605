export default {
  data() {
    return {
      comics: [
        {
          id: 1,
          cover: require('@/assets/images/comic1.jpg'),
          title: '漫画1',
          type: '热血竞技'
        },
        {
          id: 2,
          cover: require('@/assets/images/comic2.jpg'),
          title: '漫画2',
          type: '青春恋爱'
        },
        {
          id: 3,
          cover: require('@/assets/images/comic3.jpg'),
          title: '漫画3',
          type: '冒险悬疑'
        },
        {
          id: 4,
          cover: require('@/assets/images/comic4.jpg'),
          title: '漫画4',
          type: '幽默格斗'
        },
        {
          id: 5,
          cover: require('@/assets/images/comic5.jpg'),
          title: '漫画5',
          type: '热血竞技'
        },
        {
          id: 6,
          cover: require('@/assets/images/comic6.jpg'),
          title: '漫画6',
          type: '青春恋爱'
        },
        {
          id: 7,
          cover: require('@/assets/images/comic7.jpg'),
          title: '漫画7',
          type: '冒险悬疑'
        },
        {
          id: 8,
          cover: require('@/assets/images/comic8.jpg'),
          title: '漫画8',
          type: '幽默格斗'
        }
      ]
    }
  },
  methods: {
    goToComicDetail(id) {
      this.$router.push({ name: 'ComicDetail', params: { id } })
    }
  }
}
