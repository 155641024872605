<template>
  <div class="user-card-container" v-if="showUserCard" @mouseleave="hideCard">
    <div class="user-card">
      <!-- 用户信息 -->
      <div class="user-info" @click="navigateTo('profile')">
        <div class="user-avatar">
          <img :src="require('@/assets/images/default-avatar.png')" alt="默认头像" />
        </div>
        <div class="user-details">
          <p class="user-name">游客</p>
          <p class="user-type">未登录</p>
        </div>
        <button class="logout-button">
          <i class="material-icons">logout</i> 退出登录
        </button>
      </div>

      <!-- 金币与会员面板 -->
      <div class="user-panels">
        <div class="gold-panel" @click="navigateTo('wallet')">
          <div class="balance-info">
            <i class="material-icons gold-icon">monetization_on</i>
            <span class="wallet-label">金币钱包</span>
          </div>
          <p>当前余额: 100</p>
          <p>累计消费: 500</p>
          <button class="recharge-button">充值</button>
        </div>
        <div class="membership-panels">
          <div class="membership-panel normal-member" @click="navigateTo('membership')">
            <div class="member-info">
              <i class="material-icons">account_circle</i>
              <span>普通会员</span>
            </div>
            <p>尊享优惠会员特权</p>
            <button class="open-button">开通</button>
          </div>
          <div class="membership-panel premium-member" @click="navigateTo('membership')">
            <div class="member-info">
              <i class="material-icons">stars</i>
              <span>永久会员</span>
            </div>
            <p>尊享永久</p>
            <button class="open-button">开通</button>
          </div>
        </div>
      </div>

      <!-- 其他按钮 -->
      <div class="other-options">
        <button class="option-button" @click="navigateTo('basicInfo')">
          <i class="icon-basic-info material-icons">person</i> <!-- 基本资料图标 -->
          基本资料
        </button>
        <button class="option-button" @click="navigateTo('balance')">
          <i class="icon-balance material-icons">account_balance_wallet</i> <!-- 我的余额图标 -->
          我的余额
        </button>
        <button class="option-button" @click="navigateTo('membership')">
          <i class="icon-membership material-icons">stars</i> <!-- 我的会员图标 -->
          我的会员
        </button>
        <button class="option-button" @click="navigateTo('favorites')">
          <i class="icon-favorites material-icons">favorite</i> <!-- 我的收藏图标 -->
          我的收藏
        </button>
        <button class="option-button" @click="navigateTo('purchaseHistory')">
          <i class="icon-purchase-history material-icons">history</i> <!-- 购买记录图标 -->
          购买记录
        </button>
      </div>
    </div>
  </div>
</template>

<script src="../scripts/UserCard.js"></script>
<style scoped src="../styles/UserCard.css"></style>