<template>
  <div class="purchase-history-container">
    <!-- 标题 -->
    <h2 class="purchase-history-title">订单记录</h2>

    <!-- 记录列表 -->
    <div class="purchase-record" v-for="(record, index) in purchaseRecords" :key="index">
      <div class="record-content">
        <p class="record-title">金币充值</p>
        <div class="record-details">
          <span>{{ record.date }}</span>
          <span class="separator">|</span>
          <span>{{ record.paymentMethod }}</span>
          <span class="separator">|</span>
          <span>{{ record.amount }}金币</span>
        </div>
      </div>
      <span class="record-price">¥ {{ record.price }}</span>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <button class="page-button" @click="previousPage" :disabled="currentPage === 1">上一页</button>
      <span class="page-info">{{ currentPage }} / {{ totalPages }}</span>
      <button class="page-button" @click="nextPage" :disabled="currentPage === totalPages">下一页</button>
    </div>
  </div>
</template>

<script src="../../scripts/OperationArea/PurchaseHistory.js"></script>
<style scoped src="../../styles/OperationArea/PurchaseHistory.css"></style>