import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// 引入 Font Awesome 图标库的 CSS 文件
import '@fortawesome/fontawesome-free/css/all.css'; 

const app = createApp(App);
app.use(router);
app.mount('#app');

console.log("Vue App Mounted Successfully");