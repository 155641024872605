export default {
  name: 'UserFavorites',
  data() {
    return {
      favorites: [
        {
          id: 1,
          image: require('@/assets/images/comic1.jpg'),
          category: '悬疑/冒险',
          title: '《13Club战票网站》',
          description: '漫画内容 13Club战票网站漫画，专门收集「都市传说」…'
        },
        {
          id: 2,
          image: require('@/assets/images/comic2.jpg'),
          category: '日漫',
          title: '《13DOGs死囚杀戮游戏》',
          description: '内容简介 欢迎各位死囚参与13 DOGS GAME！…'
        },
        {
          id: 3,
          image: require('@/assets/images/comic3.jpg'),
          category: '日漫',
          title: '《吉祥天女》',
          description: '内容简介 《吉祥天女》讲述船叶家财产的野心分子…'
        },
        {
          id: 4,
          image: require('@/assets/images/comic4.jpg'),
          category: '日漫',
          title: '《DADADA》',
          description: '作品简介 DADADA 新连载故事，带你进入一个奇妙…'
        }
      ],
      currentPage: 1,
      itemsPerPage: 3
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.favorites.length / this.itemsPerPage)
    },
    paginatedFavorites() {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.favorites.slice(start, end)
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    }
  }
}
