import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'

export default {
  components: {
    NavBar,
    Footer
  },
  data() {
    return {
      comic: {
        title: '漫画标题',
        author: '漫画作者',
        type: '漫画类型',
        status: '连载中',
        description: '这是漫画的简介，简单介绍漫画的内容和主题。',
        cover: require('@/assets/images/comic1.jpg')
      },
      chapters: [
        { title: '第1卷' },
        { title: '第2卷' },
        { title: '第3卷' },
        { title: '第4卷' },
        { title: '第5卷' },
        { title: '第6卷' },
        { title: '第7卷' },
        { title: '第8卷' },
        { title: '第9卷' },
        { title: '第10卷' },
        { title: '第11卷' },
        { title: '第12卷' },
        { title: '第13卷' },
        { title: '第14卷' },
        { title: '第15卷' },
        { title: '第16卷' },
        { title: '第17卷' },
        { title: '第18卷' },
        { title: '第19卷' },
        { title: '第20卷' }
      ],
      comments: [
        {
          user: '用户1',
          date: '2023-10-15',
          text: '这是一本超有趣的漫画，情节紧凑！'
        },
        {
          user: '用户2',
          date: '2023-10-16',
          text: '画风精美，故事也很精彩，推荐！'
        },
        {
          user: '用户3',
          date: '2023-10-17',
          text: '等待更新的时间总是漫长的，值得期待！'
        },
        {
          user: '用户4',
          date: '2023-10-18',
          text: '剧情有些拖沓，但整体还不错。'
        },
        {
          user: '用户5',
          date: '2023-10-19',
          text: '作者的想象力真的令人惊叹！'
        },
        {
          user: '用户6',
          date: '2023-10-20',
          text: '超级棒的一部作品！收藏了！'
        },
        {
          user: '用户7',
          date: '2023-10-21',
          text: '感觉故事设定有些老套，不过还是很吸引人。'
        },
        {
          user: '用户8',
          date: '2023-10-22',
          text: '难得一见的佳作，尤其喜欢主角的成长历程。'
        },
        {
          user: '用户9',
          date: '2023-10-23',
          text: '有几个反派角色设计得很有特色。'
        },
        {
          user: '用户10',
          date: '2023-10-24',
          text: '期待后续发展，希望主角能更加强大！'
        },
        {
          user: '用户11',
          date: '2023-10-25',
          text: '每一话都很精彩，完全停不下来！'
        },
        {
          user: '用户12',
          date: '2023-10-26',
          text: '剧情高能，希望不要烂尾！'
        },
        {
          user: '用户13',
          date: '2023-10-27',
          text: '被朋友安利来的，看完后果然惊喜满满。'
        },
        {
          user: '用户14',
          date: '2023-10-28',
          text: '某些章节感觉有些重复，不过总体还可以。'
        },
        {
          user: '用户15',
          date: '2023-10-29',
          text: '这是我最喜欢的漫画之一，收藏了很久！'
        }
      ],
      newComment: '',
      relatedComics: [
        {
          title: '漫画A',
          cover: require('@/assets/images/comic1.jpg'),
          type: '热血',
          uploadDate: '2023-11-01',
          views: '3.5K',
          favorites: '1.2K'
        },
        {
          title: '漫画B',
          cover: require('@/assets/images/comic2.jpg'),
          type: '冒险',
          uploadDate: '2023-10-28',
          views: '4.8K',
          favorites: '2.5K'
        },
        {
          title: '漫画C',
          cover: require('@/assets/images/comic3.jpg'),
          type: '悬疑',
          uploadDate: '2023-10-15',
          views: '2.1K',
          favorites: '800'
        },
        {
          title: '漫画D',
          cover: require('@/assets/images/comic4.jpg'),
          type: '青春',
          uploadDate: '2023-11-02',
          views: '3.0K',
          favorites: '1.1K'
        }
      ]
    }
  },
  props: ['id'], // 可以使用该 id 从后端或本地数据中获取漫画详情
  methods: {
    submitComment() {
      if (this.newComment.trim() === '') return
      this.comments.unshift({
        user: '当前用户', // 可改为动态用户名称
        date: new Date().toLocaleDateString(),
        text: this.newComment
      })
      this.newComment = ''
    },
    goToReadingPage(chapterId) {
      this.$router.push({ name: 'ComicReader', params: { chapterId } })
    },
    goToComicDetail(comicId) {
      this.$router.push({ name: 'ComicDetail', params: { id: comicId } })
    }
  }
}
