<template>
  <div class="user-center">
    <!-- 灰色背景遮罩层 -->
    <div v-if="showCheckinSuccess" class="overlay"></div>
    <!-- 顶部用户信息模块 -->
    <div class="user-info-section">
      <div class="user-avatar">
        <img src="@/assets/images/default-avatar.png" alt="用户头像" />
      </div>
      <div class="user-info-details">
        <div class="user-name-type">
          <p class="user-name">新用户</p>
          <p class="user-type">普通</p>
        </div>
        <p class="user-email">820146275@qq.com</p>
      </div>
      <button class="logout-button">
        <i class="material-icons">exit_to_app</i> 退出登录
      </button>
    </div>

    <div class="user-center-content">
      <!-- 左侧模块导航栏 -->
      <div class="left-modules">
        <div class="module-card">
          <h5 class="module-title">金币钱包</h5>
          <div class="balance-info-row">
            <!-- 当前余额 -->
            <div class="balance-info-item" @click="navigateToRechargeCenter">
              <div class="balance-icon balance-icon-current">
                <span class="material-icons" style="font-size: 1rem"
                  >account_balance</span
                >
                <!-- 替换为简约的金币图标 -->
                <span>100</span>
              </div>
              <span>当前余额</span>
            </div>

            <!-- 累计消费 -->
            <div class="balance-info-item" @click="navigateToRechargeCenter">
              <div class="balance-icon balance-icon-total">
                <span class="material-icons" style="font-size: 1rem"
                  >credit_card</span
                >
                <!-- 简约消费图标 -->
                <span>500</span>
              </div>
              <span>累计消费</span>
            </div>
          </div>
          <div class="checkin-container">
            <!-- 每日签到按钮 -->
            <button
              class="daily-checkin-button"
              :disabled="checkinCompleted"
              @click="handleDailyCheckin"
              @mouseenter="showHoverTooltip = true"
              @mouseleave="showHoverTooltip = false"
            >
              {{ checkinCompleted ? '今日已签到' : '每日签到' }}
            </button>

            <!-- 鼠标悬停提示 -->
            <div
              v-if="showHoverTooltip && !checkinCompleted"
              class="hover-tooltip"
            >
              每日签到奖励2金币
              <div class="tooltip-arrow"></div>
            </div>
          </div>

          <!-- 签到成功提示弹窗 -->
          <div v-if="showCheckinSuccess" class="checkin-success-popup">
            <span class="close-icon material-icons" @click="closeCheckinSuccess"
              >close</span
            >
            <p>今日签到成功，奖励您2金币</p>
          </div>
        </div>

        <div class="module-card">
          <h5>会员中心</h5>
          <ul class="membership-links" style="width: 100%">
            <li
              :class="{ 'active-submenu': activeSubMenu === 'Balance' }"
              @click="navigateTo('Balance')"
            >
              我的余额
            </li>
            <li
              :class="{ 'active-submenu': activeSubMenu === 'Membership' }"
              @click="navigateTo('Membership')"
            >
              我的会员
            </li>
            <li
              :class="{ 'active-submenu': activeSubMenu === 'PurchaseHistory' }"
              @click="navigateTo('PurchaseHistory')"
            >
              购买记录
            </li>
          </ul>
        </div>

        <div class="module-card">
          <h5>账号信息</h5>
          <ul class="account-links" style="width: 100%">
            <li
              :class="{ 'active-submenu': activeSubMenu === 'UserBaseInfo' }"
              @click="navigateTo('UserBaseInfo')"
            >
              基本资料
            </li>
            <li
              :class="{ 'active-submenu': activeSubMenu === 'UserFavorites' }"
              @click="navigateTo('UserFavorites')"
            >
              我的收藏
            </li>
            <li
              :class="{
                'active-submenu': activeSubMenu === 'UserAccountBinding'
              }"
              @click="navigateTo('UserAccountBinding')"
            >
              账号绑定
            </li>
            <li
              :class="{
                'active-submenu': activeSubMenu === 'UserPasswordSetting'
              }"
              @click="navigateTo('UserPasswordSetting')"
            >
              密码设置
            </li>
          </ul>
        </div>
      </div>
      <!-- 右侧操作区域 -->
      <div class="operation-area">
        <component :is="activeComponent" v-if="activeComponent" />
      </div>
    </div>
  </div>
</template>

<script src="../scripts/UserCenter.js"></script>
<style scoped src="../styles/UserCenter.css"></style>
