export default {
  name: "SearchModal",
  data() {
    return {
      recentSearches: ["搜索词1", "搜索词2", "搜索词3"],
      tags: ["全部", "标签1", "标签2", "标签3"],
      selectedTag: "全部",
      showDropdown: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectTag(tag) {
      this.selectedTag = tag;
      this.showDropdown = false;
    },
    handleClickOutside(event) {
      if (this.showDropdown && !this.$refs.dropdown.contains(event.target)) {
        this.showDropdown = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};