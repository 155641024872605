<template>
  <div class="favorites-container">
    <h4 class="section-title">我的收藏</h4>

    <div class="favorite-list">
      <div class="favorite-item" v-for="item in paginatedFavorites" :key="item.id">
        <img :src="item.image" alt="收藏封面" class="favorite-image" />
        <div class="favorite-details">
          <div class="favorite-header">
            <span class="category">{{ item.category }}</span>
          </div>
          <h5 class="favorite-title">{{ item.title }}</h5>
          <p class="favorite-description">{{ item.description }}</p>
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <button class="page-button" @click="previousPage" :disabled="currentPage === 1">上一页</button>
      <span class="page-info">{{ currentPage }} / {{ totalPages }}</span>
      <button class="page-button" @click="nextPage" :disabled="currentPage === totalPages">下一页</button>
    </div>
  </div>
</template>

<script src="../../scripts/OperationArea/UserFavorites.js"></script>
<style scoped src="../../styles/OperationArea/UserFavorites.css"></style>