export default {
  name: "Balance",
  data() {
    return {
      selectedAmount: 48, // 默认选中的第一个项目金额
      rechargeOptions: [
        { label: "48金币", value: 48, price: 48 },
        { label: "78金币", value: 78, price: 78 },
        { label: "148金币", value: 148, price: 148 },
        { label: "6金币", value: 6, price: 6 },
      ],
      customAmount: "", // 自定义充值金额
    };
  },
  computed: {
    totalAmount() {
      // 如果有自定义金额，则使用自定义金额
      if (this.customAmount) return this.customAmount;
      // 否则返回选中的充值金额
      return this.selectedAmount || 0;
    },
  },
  methods: {
    selectAmount(amount) {
      this.selectedAmount = amount; // 设置选中的金额
      this.customAmount = ""; // 清空自定义金额
    },
    selectCustomAmount() {
      this.selectedAmount = null; // 清空选中的充值金额
    },
  },
};