<template>
  <div id="app">
    <NavBar @show-login="toggleLoginPopup" />
    <router-view />
    <Footer />
    <Login v-if="showLogin" @close="toggleLoginPopup" />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
import Login from './components/Login.vue';


export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    Login
  },
  data() {
    return {
      showLogin: false
    };
  },
  methods: {
  toggleLoginPopup() {
    this.showLogin = !this.showLogin;
    console.log("Login Popup Toggled:", this.showLogin);
  }
}
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 全局清除默认的 margin 和 padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #f0f4f8; */
  /* 柔和的蓝色背景 */
}
</style>