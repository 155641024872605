export default {
  data() {
    return {
      imagesPerLoad: 10, // 每次加载的图片数量
      totalPages: 20, // 假设总共20页（可以根据需要调整）
      pages: [
        require('@/assets/comics/VOL26363917-p1.jpg'),
        require('@/assets/comics/VOL26363917-p10.jpg'),
        require('@/assets/comics/VOL26363917-p11.jpg'),
        require('@/assets/comics/VOL26363917-p12.jpg'),
        require('@/assets/comics/VOL26363917-p13.jpg'),
        require('@/assets/comics/VOL26363917-p14.jpg'),
        require('@/assets/comics/VOL26363917-p15.jpg'),
        require('@/assets/comics/VOL26363917-p16.jpg'),
        require('@/assets/comics/VOL26363917-p17.jpg'),
        require('@/assets/comics/VOL26363917-p18.jpg')
        // 可以继续添加更多图片路径
      ],
      displayedImages: [] // 当前展示的图片列表
    }
  },
  mounted() {
    // 初始化显示前10张图片
    this.displayedImages = this.pages.slice(0, this.imagesPerLoad)
  },
  methods: {
    handleScroll(event) {
      // 检测是否滚动到容器底部
      const bottom =
        event.target.scrollHeight - event.target.scrollTop <=
        event.target.clientHeight + 50
      if (bottom) this.loadMoreImages()
    },
    loadMoreImages() {
      // 加载更多图片
      const nextImages = this.pages.slice(
        this.displayedImages.length,
        this.displayedImages.length + this.imagesPerLoad
      )
      this.displayedImages = [...this.displayedImages, ...nextImages]
    }
  }
}
