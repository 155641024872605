<template>
  <div class="basic-info-container">
    <h4 class="section-title">个人基本信息</h4>

    <div class="info-form">
      <!-- 头像上传区域 -->
      <div class="avatar-upload">
        <img src="@/assets/images/default-avatar.png" alt="头像" class="avatar-image" />
        <i class="material-icons upload-icon">camera_alt</i>
      </div>

      <!-- 资料输入表单 -->
      <div class="form-fields">
        <div class="form-row">
          <label for="account-id">账号ID</label>
          <input type="text" id="account-id" value="mail_34656166" disabled class="input-field" />
        </div>

        <div class="form-row">
          <label for="nickname">昵称</label>
          <input type="text" id="nickname" placeholder="新用户" class="input-field" />
        </div>

        <div class="form-row">
          <label for="qq">联系QQ</label>
          <input type="text" id="qq" placeholder="请输入QQ" class="input-field" />
        </div>

        <div class="form-row">
          <label for="phone">电话</label>
          <input type="text" id="phone" placeholder="请输入电话" class="input-field" />
        </div>

        <div class="form-row full-width">
          <label for="introduction">介绍</label>
          <textarea id="introduction" placeholder="请输入个人介绍：" class="textarea-field"></textarea>
        </div>
      </div>
    </div>

    <!-- 保存按钮 -->
    <button class="save-button" @click="saveInfo">保存个人信息</button>
  </div>
</template>

<script src="../../scripts/OperationArea/UserBaseInfo.js"></script>
<style scoped src="../../styles/OperationArea/UserBaseInfo.css"></style>
